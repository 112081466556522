.tabbsWrapper {
  border-radius: 6px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 133.012px 106.41px 0px rgba(0, 0, 0, 0.02),
    0px 86.212px 62.319px 0px rgba(0, 0, 0, 0.02),
    0px 51.234px 33.893px 0px rgba(0, 0, 0, 0.01),
    0px 10.838px 8.67px 0px rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(41.29999923706055px);
  padding: 20px 40px;
}
.tabbsWrapper .initiativeTabs {
  border-bottom: 2px solid #b1b1b13b;
  display: flex;
  justify-content: space-between;
}
.initiativeTabs button {
  background: none;
  border-bottom: 2px solid transparent !important;
  border: none;
  color: #1e1e1e;
  padding: 20px;
  position: relative;
  bottom: -1px;
  opacity: 0.7;
}
.initiativeTabs button:hover svg {
    opacity: 1;
}
.initiativeTabs button svg {
    opacity: 0.4;
    width: 22px;
    height: 22px;
    margin-right: 7px;
}
.initiativeTabs button:hover svg path,
.initiativeTabs button.active svg path{
    fill: #E4B131;
}
.initiativeTabs button.active svg{
    opacity: 1;
}
.initiativeTabs button:hover,
.initiativeTabs button.active {
  border-bottom: 2px solid #e4b131 !important;
  opacity: 1;
}
.secTitle span {
  background: linear-gradient(180deg, #fff200 -14.38%, #e4b131 71.4%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.secTitle {
  font-size: 37px;
  font-family: "Avantt_semibold", sans-serif;
}
.inCard {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #d4d4d4;
}
.inCard:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

@media (min-width: 768px) {
  .flip .row {
    flex-direction: row-reverse;
  }
}
